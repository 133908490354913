import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import { Router } from "@reach/router";
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Redirect,
// } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./app";
import CancelCard from "./app-page/CancelCard";
import PartnerWithUs from "./partner-with-us";
// import Privacy from "./privacy-policy";
// import Terms from "./terms";

const IndexPage = () => {
  return (
    <React.Fragment>
      <App />
      {/* <BrowserRouter>
        <Switch>
          <Route path="/cancel" render={(props) => <CancelCard {...props} />} />
        </Switch>
      </BrowserRouter> */}

      {/* <Router basepath="/app">
        <CancelCard path="/cancel-card" />
      </Router> */}
    </React.Fragment>
  );
};

export default IndexPage;
